import React from 'react';
import { useSudokuContext } from '../context/SudokuContext';

/**
 * React component for the Number Selector in the Status Section.
 */
export const Numbers = (props) => {
  let { numberSelected } = useSudokuContext();

  return (
    <div className="status__numbers border-r border-t border-gray-200">
      {
        [1,2,3,4,5,6,7,8,9].map((number) => {
          if (numberSelected === number.toString()) {
            return (
              <div className="status__number text-3xl status__number--selected px-6 py-6 border-l border-b border-gray-200 text-gray-700" key={number} onClick={() => props.onClickNumber(number.toString())}>{number}</div>
            )
          } else {
            return (
              <div className="status__number text-3xl px-6 py-6 border-l border-b border-gray-200 text-gray-700" key={number} onClick={() => props.onClickNumber(number.toString())}>{number}</div>
            )
          }
        })
      }
    </div>
  )
}
