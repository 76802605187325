import React, { Component } from "react";

class TwoColumns extends Component {
  render() {
    return (
<div className="bg-white">
<div className="md:flex md:items-center md:justify-between">
    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate pl-4 mt-4">
      How to Play Free Sudoku Online
    </h2>

</div>    
<div>
  <div className="max-w-screen-xl mx-auto pt-6 pb-6 px-4">
      <dl className="md:grid md:grid-cols-2 md:gap-8">
        <div>
          <div>
            <dt className="text-lg leading-6 font-medium text-gray-900">
              What is the goal of Sudoku?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                The goal of Sudoku is to fill a 9x9 grid with numbers so that each column, each row, and each of the nine 3x3 boxes that compose the grid contain every digit from 1 to 9. The game starts with a partially completed grid, for which the game has a single solution.
              </p>
            </dd>
          </div>
          <div className="mt-12">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              What constitutes a "wrong move" in Sudoku?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                If any row, column, or box contains each number from 1 to 9 more than once, the solution is deemed incorrect. Use the "Stop Mistakes" setting to automatically block a wrong move.
              </p>
            </dd>
          </div>
        </div>
        <div className="mt-12 md:mt-0">
          <div>
            <dt className="text-lg leading-6 font-medium text-gray-900">
              What's the Timer for?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                Sudoku is a great way to train your brain and improve your concentration. The timer is a motivational tool to help your brain learn - over time - how to solve Sudoku puzzles faster.
              </p>
            </dd>
          </div>
          <div className="mt-12">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              What do the options Easy, Medium, and Hard mean?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                In Easy mode, the Sudoku game starts with more pre-filled numbers. In Hard mode, the game of Sudoku starts with a smaller number of pre-filled numbers.
              </p>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</div>



    );
  }
}

export default TwoColumns;
