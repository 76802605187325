import React, { Component } from "react";
import { Whatsapp, Facebook, Twitter } from 'react-social-sharing';

class Sharing extends Component {
  render() {
    return (

<div className="bg-gray-50 shadow sm:rounded-lg mt-8 mb-8 md:mt-40">
  <div className="px-4 py-5 sm:p-6">
    <h3 className="text-xl leading-6 font-medium text-gray-900 pl-2">
      Play With Friends
    </h3>
    <div className="mt-2 max-w-xl text-md leading-5 text-gray-500 pl-2">
      <p>
        Share Sudoku with your friends, and challenge them to a high score battle! 👇
      </p>
    </div>
    <div className="mt-2 sm:flex sm:items-center font-bold">
        <Whatsapp link="https://www.sudoku.ie" message="Challenge me to a game of Free Sudoku Online at Sudoku.ie"/>
        <Facebook link="https://www.sudoku.ie" message="Challenge me to a game of Free Sudoku Online at Sudoku.ie"/>
        <Twitter link="https://www.sudoku.ie" message="Challenge me to a game of Free Sudoku Online at Sudoku.ie"/>
    </div>
  </div>
</div>


    );
  }
}

export default Sharing;
