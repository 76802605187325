import React from 'react';
import { useSudokuContext } from '../context/SudokuContext';

/**
 * React component for the Difficulty Selector.
 */
export const Difficulty = (props) => {
  let { difficulty } = useSudokuContext();

  return (
    <>


            <div className="inline-flex pt-2 justify-center w-full bg-white leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
            <div>
            <label>  
            <select name="status__difficulty-select" className="status__difficulty-select block mt-1 px-8 py-2 text-gray-700 rounded-md shadow-xs w-full" defaultValue={difficulty} onChange={props.onChange}>
                <option value="Easy" className="block px-8 py-4 text-gray-700">Easy</option>
                <option value="Medium" className="block px-8 py-2 text-gray-700">Medium</option>
                <option value="Hard" className="block px-8 py-2 text-gray-700">Hard</option>
            </select>
            </label>  
            </div>
        </div>
    </>
  )
}
