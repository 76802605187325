import React from 'react';
import { Difficulty } from '../Difficulty';
import { Timer } from '../Timer';


/**
 * React component for the Status Section.
 */
export const StatusSectionTop = (props) => {
  return (
    <>
      <div class="col-span-4 flex justify-center pt-3 px-2">
        <Difficulty onChange={props.onChange} />
      </div>
      <div class="col-span-2 flex justify-center pt-3 px-2">
        <Timer />
      </div>  
    </>
  )
}
