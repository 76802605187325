import React from 'react';
import { Numbers } from '../Numbers';
import { Action } from '../Action';
import { Mode } from '../Mode';

/**
 * React component for the Status Section.
 */
export const StatusSectionBottom = (props) => {
  return (
    <section className="status">
      <Numbers onClickNumber={(number) => props.onClickNumber(number)} />
      <div className="status__actions border-r border-gray-200">
        <Action action='undo' onClickAction={props.onClickUndo} />
        <Action action='erase' onClickAction={props.onClickErase} />
        <Action action='hint' onClickAction={props.onClickHint} />
        <Mode mode='mistakes' onClickMode={props.onClickMistakesMode} />
        <Mode mode='fast' onClickMode={props.onClickFastMode} />
      </div>
    </section>
  )
}
