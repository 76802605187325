import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";

{/* FAQs*/}
import { App } from "../components/Sudoku/App";
import TwoColumns from "../components/FAQs/TwoColumns";



class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <div className="index-container">
          <Helmet>
            <title>{`Play Sudoku online for free | ${config.siteTitle}`}</title>
            <meta name="description" content="Play Sudoku with your friends, and challenge them to a high score battle!" />
            {/* OpenGraph tags */}
            <meta name="og:url" content="https://www.sudoku.ie" />
            <meta name="og:title" content={config.siteTitle} />
            <meta name="og:description" content="Play Sudoku with your friends, and challenge them to a high score battle!" />
            <meta name="og:image" content="https://www.sudoku.ie/images/sudoku.jpg"/>
            <meta name="og:type" content="website" />
            <meta name="fb:app_id" content="124806194853044" />         
          </Helmet>

          <App />

          {/* FAQs*/}
          <TwoColumns />


        </div>
      </Layout>
    );
  }
}

export default IndexPage;
