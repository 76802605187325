import React from 'react';

/**
 * React component for the Header Section.
 */
export const Header = (props) => {
  return (

    <span class="inline-flex rounded-md shadow-sm">
    <button type="button"  onClick={props.onClick} className="inline-flex items-center px-4 py-2 mt-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150">
        New Game
    </button>
    </span>    
    
  )
}
